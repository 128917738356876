<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top mb-10">
                <h2><span>■</span> 상담예약 상세화면</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>고객명</th>
                                <td class="left pl-20" style="min-width: 200px;">
                                    {{name}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담예약현황</th>
                                <td class="left pl-20">
                                    {{reservationStatus}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담날짜</th>
                                <td class="left pl-20">
                                    {{formatDate(consDate)}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담시간</th>
                                <td class="left pl-20">
                                    {{consTime}}
                                </td>
                            </tr>
                            <tr>
                                <th>메모</th>
                                <td class="left pl-20">
                                    {{reservationMemo}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span>고객 기본정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">고객번호</th>
                                <td class="left pl-20  w-400px">
                                    {{clientCode}}
                                </td>
                                <th class="w-120px">고객유형</th>
                                <td class="left pl-20">
                                    {{eapType}}
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td class="left pl-20">
                                    {{name}}
                                </td>
                                <th>성별</th>
                                <td class="left pl-20">
                                    {{gender}}
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td class="left pl-20">
                                    {{formatDate(birth)}}
                                </td>
                                <th>이메일</th>
                                <td class="left pl-20">
                                    {{email}}
                                </td>
                            </tr>
                            <tr>
                                <th>소속</th>
                                <td class="left pl-20">
                                    {{companyName}}
                                </td>
                                <th>유입경로</th>
                                <td class="left pl-20">
                                    {{routeName}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                        <tbody>
                            <tr>
                                <th class="w-120px">특이사항</th>
                                <td class="left pl-20 h-40px">
                                    {{clientMemo}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span>상담정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">상담센터</th>
                                <td class="left pl-20  w-400px">
                                    {{centerName}}
                                </td>
                                <th class="w-120px">상담사</th>
                                <td class="left pl-20 reset-br">
                                    {{staffName}}
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr>
                            <tr>
                                <th>상담구분</th>
                                <td class="left pl-20">
                                    {{gubun}} {{productName}} <!-- ({{numberWithCommas(productPrice)}} 원) -->
                                </td>
                                <th>상담유형</th>
                                <td class="left pl-20" colspan="3">
                                    {{typeName}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담주제</th>
                                <td class="left pl-20">
                                    {{subjectName}}
                                </td>
                                <th>진행현황</th>
                                <td class="left pl-20" colspan="3">
                                    {{clientStatus}}
                                </td>
                            </tr>
                            <tr>
                                <th>사용가능회기</th>
                                <td class="left pl-20" colspan="5">
                                    {{consCountUse}}회 (총{{consCount}}회)
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">뒤로</a>
                        <a v-if="reportYn === 'N'" class="btn_sms mr-10 pointer" @click="register(idxReservation)">일지작성</a>
                        <!-- <a class="btn_cancle mr-10 pointer" @click="remove()">삭제</a>
                        <a class="btn_cancle pointer" @click="modify()">수정</a> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        idx: -1,
        idxClient: -1,
        clientMemo: '',
        name: '',
        clientCode: '',
        eapType: '',
        gender: '',
        birth: '',
        email: '',
        companyName: '',
        routeName: '',

        idxReservation: -1,
        reservationMemo: '',
        reservationStatus: '',
        centerName: '',
        staffName: '',
        typeName: '',
        gubun: '',
        productName: '',
        productPrice: '',
        subjectName: '',
        consCount: 0,
        consCountUse: 0,
        consCountRest: 0,
        clientStatus: '',
        consTime: '',
        consDate: '',
        etcPrice: 0,
        reportYn: "N",
    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getReservation(this.idx)
    },

    methods: {

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        getReservation(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/cons/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {

                        this.idxReservation = res.data.resVO.idx
                        this.reservationMemo = res.data.resVO.memo
                        this.staffName = res.data.resVO.staffName
                        this.centerName = res.data.clientVO.centerName
                        this.consTime = res.data.resVO.consTime
                        this.reservationStatus = res.data.resVO.reservationStatus
                        this.consDate = res.data.resVO.consDate
                        this.gubun = res.data.clientVO.gubun
                        this.productName = res.data.clientVO.productName
                        this.productPrice = res.data.clientVO.productPrice
                        this.typeName = res.data.clientVO.typeName
                        this.clientStatus = res.data.clientVO.clientStatus
                        this.consCount = res.data.clientVO.consCount
                        this.consCountUse = res.data.clientVO.consCountUse
                        this.consCountRest = res.data.clientVO.consCountRest
                        this.name = res.data.clientVO.name
                        this.gender = res.data.clientVO.gender
                        this.birth = res.data.clientVO.birth
                        this.email = res.data.clientVO.email
                        this.routeName = res.data.clientVO.routeName
                        this.companyName = res.data.clientVO.companyName
                        this.clientMemo = res.data.clientVO.memo
                        this.clientCode = res.data.clientVO.clientCode
                        this.idxClient = res.data.clientVO.idx
                        if(res.data.clientVO.gubun === '기타') {
                            this.productPrice = res.data.clientVO.etcPrice
                        }
                        this.reportYn = res.data.resVO.reportYn

                    }
                })
        },

        // 이전 페이지로 돌아가기
        cancel() {
            history.back()
        },

        // 상담일지 등록페이지로 이동
        register (idxReservation) {
            this.$router.push(`/counselor/journal_register?idxCrmReservation=${idxReservation}`)
        },

        /* // 상담예약 삭제
        remove () {
             var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                alert('삭제되었습니다.')
                this.$router.push('/counselor/reservation')
            } else {
                return false
            }
        },

        // 수정 페이지로 이동
        modify () {

        } */
    }
}
</script>
